@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&family=Space+Grotesk:wght@300..700&display=swap');
$main_purple:#6200ff;
$main_white:#FCFCFC;
$light_gray:#C6C6C6;
$main_gray:#3F3F3F;
$main_black:#3C3C3C;
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif;
  list-style-type: none;
  text-decoration: none;
}

.App{
  background-color: $main_white;
  color:$main_gray;
  margin: auto;
  &.dark_mode{
    background-color: $main_black;
    color:$light_gray;
  }
}

nav{
  width: 80vw;
  margin: auto;
  padding-top: 25px;
    @media(max-width:767px){
    flex-direction: column;
    justify-content: center;
  }
}




nav ul li{
  font-weight: bold;
  transition: 0.5s ease-in-out;
  &:hover{
    color: $main_purple;
    cursor: pointer;
  }
}

header{
  height: 20vh;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width:767px){
    justify-content: center;
    h1{
      font-size: 22px;
    }
    h2{
      font-size: 20px;
    }
  }
}

header img{
  height: 100px;
  width: 125px;
  @media(max-width:767px){
    height: 75px;
    width: 90px;
  }
}

main{
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: auto;
  min-height: 75vh;
}

main button{
  background: $main_black;
  color: $main_white;
  margin: 28px;
  padding: 15px;
  width: 450px;
  margin: auto;
  outline: none;
  border: none;
  border: 4px solid $main_gray;
  margin-bottom: 50px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  &.dark_mode{
    border-color:$light_gray;
  }
  &:hover{
    background: $main_white;
    color: $main_black;
    &.dark_mode{
      background: $light_gray;
    }
  }
  @media(max-width:999px){
    width: 350px;
  }
  @media(max-width:767px){
    width: 325px;
  }
}

main h4{
  margin: auto;
  font-size: 15px;
}

main #card_area{
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80vw;
  @media(max-width:767px){
    width: 80vw;
  }
}

main #card_individual img{
  width: 450px;
  height: 575px;
  border: 4px solid $main_gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s ease-in-out;
  margin-bottom: 45px;
  opacity: 0.8;
  cursor: pointer;
  &.dark_mode{
    border-color:$light_gray;
  }
  &:hover{
    transform: scale(1.1);
    opacity: 1;
  }
  @media(max-width:999px){
    width: 350px;
    height: 475px;
    margin: auto;
    margin-bottom: 15px;
  }
  @media(max-width:767px){
    width: 325px;
    height: 450px;
  }
}


footer ul{
  width: 100vw;
  display: flex;
  justify-content: space-between;
  font-family: "Space Grotesk", sans-serif;
  font-size: 17px;
  font-family: bold;
  padding: 20px;
  @media(max-width:767px){
    flex-direction: column;
    align-items: center;
  }
  a{
    color: $main_black;
    text-decoration: underline;
    font-weight:700;
    &.dark_mode{
      color: $light_gray;
    }
  }
}